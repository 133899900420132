import { createClient, cacheExchange, fetchExchange } from "@urql/vue";

export const client = createClient({
  url: process.env.VUE_APP_BACKEND_URI,
  exchanges: [cacheExchange, fetchExchange],
  requestPolicy: "network-only",
  fetchOptions: () => {
    return {
      headers: {
        app: "dashboard",
      },
      credentials: "include",
    };
  },
});
