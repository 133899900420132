import { createApp } from "vue";
import { createPinia } from 'pinia'
import App from "./App.vue";
import router from "./router";
import urql from "@urql/vue";
import { client } from "./client.js";
import appear from "./directive/appear.js";

import cookie from "@/utilities/cookie";

// boostrap stylesheet
import "../src/assets/scss/main.scss"
// bootstrap
import "bootstrap"
// bootstrap icons
import "bootstrap-icons/font/bootstrap-icons.css";
// custom css
import "./assets/style.css";

fetch(`/meta.json?${new Date().getTime()}`, { cache: "no-cache" })
  .then((response) => response.json())
  .then((meta) => {
    const latestVersion = meta.version;
    const versionFromCookie = cookie.getSecureCookie("slLandingVer", false);

    if (versionFromCookie != latestVersion) {
      cookie.setSecureCookie("slLandingVer", latestVersion, true, true, false);
      if (caches) {
        caches.keys().then((names) => {
          for (let name of names) caches.delete(name);
        });
      }
      window.location.reload(true);
    }
  });

// vue app
const app = createApp(App);

// vue app
app.use(urql, client);
app.use(createPinia())
app.directive('appear', appear);
app.use(router).mount("#app");
