const cookie = {
  getCookie(name) {
    let value = `; ${document.cookie}`;
    let parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  },
  setCookie(key, value, maxAge) {
    if (maxAge) {
      document.cookie = `${key}=${value};max-age=31536000`;
    } else {
      document.cookie = `${key}=${value}`;
    }
  },
  setSecureCookie(key, value, maxAge, sameSite) {
    let sameSiteValue = "lax";
    if (sameSite == true) {
      sameSiteValue = "strict";
    }
    if (maxAge) {
      document.cookie = `${key}=${value};max-age=31536000;secure;samesite=${sameSiteValue}`;
    } else {
      document.cookie = `${key}=${value};secure;samesite=${sameSiteValue}`;
    }
  },
  getSecureCookie(name) {
    let value = `; ${document.cookie}`;
    let parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      let value = parts.pop().split(";").shift();
      return value;
    } else {
      return false;
    }
  },
  removeSecureCookie(name) {
    document.cookie = `${name}=;max-age=0;secure;samesite=strict`;
  },
};

export default cookie;
